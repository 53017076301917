import { useState } from 'react';
import { Shield, Heart, Stethoscope } from 'lucide-react';
import PlanCard from '../../components/insurance/shared/PlanCard';

const marketplacePlans = [
  {
    name: 'Marketplace Individual Plans',
    description: 'ACA-compliant with potential subsidies',
    icon: Shield,
    features: [
      'Premium tax credits available',
      'Cost-sharing reductions',
      'Essential health benefits',
      'Guaranteed issue'
    ],
    benefits: [
      'Income-based affordability',
      'Preventive care at no cost',
      'No pre-existing condition exclusions',
      'Standardized benefit tiers'
    ]
  },
  {
    name: 'Marketplace Family Coverage',
    description: 'Subsidized protection for families',
    icon: Heart,
    features: [
      'Family premium subsidies',
      'Family out-of-pocket maximums',
      'Pediatric dental & vision',
      'Maternity coverage'
    ],
    benefits: [
      'Income-based premium assistance',
      'Children covered to age 26',
      'Comprehensive family coverage',
      'Special enrollment periods'
    ]
  }
];

const offMarketplacePlans = [
  {
    name: 'Short-Term Health Plans',
    description: 'Temporary coverage solutions',
    icon: Shield,
    features: [
      'Flexible coverage periods',
      'Quick application process',
      'Lower premiums than ACA plans',
      'Coverage for unexpected illness/injury'
    ],
    benefits: [
      'Bridging coverage gaps',
      'No open enrollment restrictions',
      'Customizable coverage options',
      'Rapid approval process'
    ]
  },
  {
    name: 'Health Share Programs',
    description: 'Alternative to traditional insurance',
    icon: Heart,
    features: [
      'Faith-based or community options',
      'Monthly sharing contributions',
      'Medical expense sharing',
      'Flexible program structure'
    ],
    benefits: [
      'Often lower monthly costs',
      'Community-focused approach',
      'Exemption from ACA mandate',
      'Value-aligned healthcare'
    ]
  },
  {
    name: 'Self-Funded Options',
    description: 'Employer-sponsored alternatives',
    icon: Stethoscope,
    features: [
      'Level-funded plans',
      'Reference-based pricing',
      'Customized plan design',
      'Direct primary care integration'
    ],
    benefits: [
      'Potential cost savings',
      'Customized benefits',
      'Data transparency',
      'Regulatory flexibility'
    ]
  }
];

export default function HealthInsurancePage() {
  const [activeTab, setActiveTab] = useState('marketplace');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="py-16 lg:py-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Health Insurance Solutions
            </h1>
            <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
              Comprehensive health insurance solutions designed to provide quality
              healthcare coverage at competitive rates.
            </p>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Health Insurance Navigation">
          <button
            onClick={() => setActiveTab('marketplace')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'marketplace' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Marketplace Plans
          </button>
          <button
            onClick={() => setActiveTab('off-marketplace')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'off-marketplace' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Off-Marketplace Plans
          </button>
        </nav>

        {/* Plans Grid */}
        <div className="mb-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {activeTab === 'marketplace' && 
            marketplacePlans.map((plan) => (
              <PlanCard key={plan.name} plan={plan} />
            ))
          }
          {activeTab === 'off-marketplace' && 
            offMarketplacePlans.map((plan) => (
              <PlanCard key={plan.name} plan={plan} />
            ))
          }
        </div>

        {/* Comparison Table */}
        <div className="mb-16 overflow-hidden rounded-xl bg-white shadow ring-1 ring-gray-200">
          <div className="p-6 sm:p-8">
            <h2 className="text-xl font-semibold text-gray-900">
              Plan Comparison: Marketplace vs. Off-Marketplace
            </h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Features
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Marketplace Plans
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Off-Marketplace Plans
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                <tr>
                  <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                    Premium Subsidies
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    Available
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    Not Available
                  </td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                    Essential Health Benefits
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    Required
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    Varies by Plan
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                    Enrollment Period
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    Limited to Open Enrollment
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    Year-Round Enrollment
                  </td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                    Pre-existing Conditions
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    Covered
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    May Be Limited
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                    Monthly Cost
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    Higher (with subsidies: varies)
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    Generally Lower
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
}