export const ANNUITY_SOLUTIONS = {
  pageTitle: 'Annuity Solutions',
  heroSection: {
    headline: 'Comprehensive Annuity Solutions',
    subheadline: 'Secure retirement income options for your clients',
    backgroundImage: '/images/annuity-hero.jpg'
  },
  productCategories: [
    {
      name: 'Fixed Annuities',
      description: 'Guaranteed rate of return with principal protection',
      features: [
        {
          title: 'Guaranteed Interest Rates',
          description: 'Predictable returns regardless of market conditions'
        },
        {
          title: 'Principal Protection',
          description: 'No risk of loss due to market downturns'
        },
        {
          title: 'Tax-Deferred Growth',
          description: 'Compound earnings without annual tax consequences'
        },
        {
          title: 'Income Options',
          description: 'Multiple payout choices including lifetime income'
        }
      ]
    },
    {
      name: 'Indexed Annuities',
      description: 'Market-linked growth potential with downside protection',
      features: [
        {
          title: 'Market Participation',
          description: 'Growth tied to market index performance'
        },
        {
          title: 'Downside Protection',
          description: 'Principal protection from market losses'
        },
        {
          title: 'Cap and Participation Rates',
          description: 'Defined limits on potential returns'
        },
        {
          title: 'Income Riders',
          description: 'Optional benefits for guaranteed lifetime income'
        }
      ]
    },
    {
      name: 'Multi-Year Guaranteed Annuities (MYGA)',
      description: 'Fixed rates guaranteed for multiple years',
      features: [
        {
          title: 'Rate Lock Periods',
          description: 'Guaranteed rates for 3, 5, 7, or 10 years'
        },
        {
          title: 'Competitive Yields',
          description: 'Often higher than CDs or money market accounts'
        },
        {
          title: 'Simplified Structure',
          description: 'Straightforward product design'
        },
        {
          title: 'Renewal Options',
          description: 'Flexibility at the end of guarantee period'
        }
      ]
    }
  ],
  importantInformation: {
    title: 'Key Annuity Considerations',
    points: [
      {
        title: 'Surrender Periods',
        description: 'Withdrawal limitations and potential penalties during initial contract years'
      },
      {
        title: 'Tax Implications',
        description: 'Tax-deferred growth with potential penalties for early withdrawals before age 59½'
      },
      {
        title: 'Fees and Expenses',
        description: 'Understanding all costs associated with the annuity product'
      },
      {
        title: 'Suitability',
        description: 'Proper client profiling to ensure appropriate product recommendations'
      }
    ]
  },
  comparisonTable: {
    title: 'Annuity Product Comparison',
    headers: ['Features', 'Fixed Annuity', 'Indexed Annuity', 'MYGA'],
    rows: [
      {
        feature: 'Market Risk',
        fixed: 'None',
        indexed: 'Limited',
        myga: 'None'
      },
      {
        feature: 'Growth Potential',
        fixed: 'Low to Moderate',
        indexed: 'Moderate',
        myga: 'Low to Moderate'
      },
      {
        feature: 'Guaranteed Period',
        fixed: 'Varies',
        indexed: 'Varies',
        myga: 'Specific Term'
      },
      {
        feature: 'Income Options',
        fixed: 'Multiple',
        indexed: 'Multiple',
        myga: 'Limited During Term'
      },
      {
        feature: 'Liquidity',
        fixed: 'Limited',
        indexed: 'Limited',
        myga: 'Very Limited'
      }
    ]
  }
};