import { type ReactNode } from 'react';

interface ComparisonRow {
  feature: string;
  partA: ReactNode;
  partB: ReactNode;
  partC: ReactNode;
  partD: ReactNode;
  medigap: ReactNode;
}

const comparisonData: ComparisonRow[] = [
  {
    feature: 'Coverage Type',
    partA: 'Hospital Insurance',
    partB: 'Medical Insurance',
    partC: 'Medicare Advantage',
    partD: 'Prescription Drug Coverage',
    medigap: 'Medicare Supplement',
  },
  {
    feature: 'What It Covers',
    partA: 'Hospital stays, skilled nursing facility, hospice, home health care',
    partB: 'Doctor visits, outpatient care, preventive services, medical supplies',
    partC: 'Combines Part A, B, and usually D plus additional benefits',
    partD: 'Prescription medications',
    medigap: 'Helps pay costs not covered by Original Medicare (Parts A & B)',
  },
  {
    feature: 'Cost',
    partA: 'Usually premium-free if you paid Medicare taxes while working',
    partB: '$174.70/month (2024 standard premium)',
    partC: 'Varies by plan, many $0 premium options',
    partD: 'Varies by plan, average $48/month (2024)',
    medigap: 'Varies by plan, age, location, and insurer',
  },
  {
    feature: 'Deductibles',
    partA: '$1,632 per benefit period (2024)',
    partB: '$240 annually (2024)',
    partC: 'Varies by plan',
    partD: 'Maximum $545 (2024)',
    medigap: 'Varies by plan',
  },
  {
    feature: 'Provider Networks',
    partA: 'Any provider that accepts Medicare',
    partB: 'Any provider that accepts Medicare',
    partC: 'Usually limited to plan network providers',
    partD: 'Pharmacy networks vary by plan',
    medigap: 'Any provider that accepts Medicare',
  },
  {
    feature: 'Enrollment Periods',
    partA: 'Initial Enrollment Period (IEP); Special Enrollment Period (SEP)',
    partB: 'Initial Enrollment Period (IEP); General Enrollment Period (GEP); Special Enrollment Period (SEP)',
    partC: 'Initial Enrollment Period (IEP); Annual Enrollment Period (AEP); Medicare Advantage Open Enrollment Period (OEP)',
    partD: 'Initial Enrollment Period (IEP); Annual Enrollment Period (AEP)',
    medigap: 'Medigap Open Enrollment Period (6 months from Part B enrollment)',
  },
  {
    feature: 'How to Enroll',
    partA: 'Automatic at 65 with Social Security; or apply through SSA',
    partB: 'Automatic at 65 with Social Security; or apply through SSA',
    partC: 'Through private insurers',
    partD: 'Through private insurers',
    medigap: 'Through private insurers',
  },
  {
    feature: 'Late Enrollment Penalty',
    partA: 'Potentially 10% higher premium for twice the number of years you could have had Part A',
    partB: '10% premium increase for each 12-month period you delay',
    partC: 'No specific penalty, but Part B penalties apply',
    partD: '1% per month for every month without creditable coverage',
    medigap: 'No federal penalty, but may have medical underwriting later',
  }
];

export default function MedicareComparisonTable() {
  return (
    <div className="overflow-x-auto">
      <div className="inline-block min-w-full align-middle">
        <div className="overflow-hidden rounded-lg border border-gray-200 shadow-sm">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Feature
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Medicare Part A
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Medicare Part B
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Medicare Part C (Advantage)
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Medicare Part D
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Medigap
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {comparisonData.map((row, rowIdx) => (
                <tr key={rowIdx} className={rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {row.feature}
                  </td>
                  <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 max-w-xs">
                    {row.partA}
                  </td>
                  <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 max-w-xs">
                    {row.partB}
                  </td>
                  <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 max-w-xs">
                    {row.partC}
                  </td>
                  <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 max-w-xs">
                    {row.partD}
                  </td>
                  <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 max-w-xs">
                    {row.medigap}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}