import { useState } from 'react';
import { Book, Video, FileText, Calendar } from 'lucide-react';
import Button from '../../components/ui/Button';

const courses = [
  {
    title: 'Insurance Fundamentals',
    description: 'Master the basics of insurance products and industry concepts',
    icon: Book,
    modules: [
      'Insurance Industry Overview',
      'Product Types and Features',
      'Risk Assessment Basics',
      'Policy Components'
    ],
    duration: '4 hours',
    level: 'Beginner'
  },
  {
    title: 'Advanced Sales Techniques',
    description: 'Learn proven strategies for insurance sales success',
    icon: Video,
    modules: [
      'Needs Analysis',
      'Objection Handling',
      'Client Presentations',
      'Closing Techniques'
    ],
    duration: '6 hours',
    level: 'Intermediate'
  },
  {
    title: 'Compliance Training',
    description: 'Stay current with regulatory requirements and best practices',
    icon: FileText,
    modules: [
      'Regulatory Framework',
      'Privacy Requirements',
      'Marketing Guidelines',
      'Documentation Standards'
    ],
    duration: '3 hours',
    level: 'Required'
  }
];

export default function TrainingPage() {
  const [activeTab, setActiveTab] = useState('courses');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="py-16 lg:py-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Training & Development
            </h1>
            <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
              Access comprehensive training resources and professional development
              opportunities to enhance your insurance career.
            </p>
          </div>
        </div>

        {/* Daily Training Call */}
        <div className="mb-16 rounded-2xl bg-gradient-to-r from-blue-600 to-blue-700 p-8 text-white shadow-lg">
          <div className="flex flex-col gap-6 md:flex-row md:items-center md:justify-between">
            <div>
              <h2 className="text-2xl font-bold">Daily Training Call</h2>
              <p className="mt-2 max-w-2xl">
                Join our daily training calls to stay updated on the latest products, 
                techniques, and industry developments. Connect with our team of experts 
                and fellow agents.
              </p>
              <div className="mt-4 flex flex-wrap items-center gap-4 text-blue-100">
                <div className="flex items-center gap-2">
                  <Calendar className="h-5 w-5" />
                  <span>Monday - Friday, 9:00 AM EST</span>
                </div>
              </div>
            </div>
            <Button className="whitespace-nowrap bg-white px-6 py-3 text-blue-700 hover:bg-gray-100">
              <a href="https://zoom.us/j/123456789" target="_blank" rel="noopener noreferrer">
                Join Zoom Meeting
              </a>
            </Button>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Training Navigation">
          <button
            onClick={() => setActiveTab('courses')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'courses' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Available Courses
          </button>
          <button
            onClick={() => setActiveTab('certifications')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'certifications' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Certification Tracks
          </button>
        </nav>

        {/* Course Grid */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {courses.map((course) => (
            <div
              key={course.title}
              className="group relative overflow-hidden rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50 transition-all hover:shadow-xl"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
              
              <div className="relative">
                {/* Course Header */}
                <div className="flex items-center gap-4">
                  <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                    <course.icon className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">
                      {course.title}
                    </h3>
                    <p className="text-sm text-gray-600">{course.description}</p>
                  </div>
                </div>

                {/* Course Details */}
                <div className="mt-6 flex items-center gap-4 text-sm text-gray-600">
                  <span>{course.duration}</span>
                  <span>•</span>
                  <span>{course.level}</span>
                </div>

                {/* Module List */}
                <div className="mt-6">
                  <h4 className="text-sm font-medium text-gray-900">
                    Course Modules
                  </h4>
                  <ul className="mt-4 space-y-3">
                    {course.modules.map((module) => (
                      <li
                        key={module}
                        className="flex items-center text-sm text-gray-600"
                      >
                        <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                        {module}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Action Button */}
                <div className="mt-8">
                  <Button
                    variant="outline"
                    className="w-full justify-center transition-transform hover:-translate-y-0.5"
                  >
                    Start Course
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}