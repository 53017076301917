import { useState } from 'react';
import IndividualPlans from '../../components/insurance/life/IndividualPlans';
import FamilyCoverage from '../../components/insurance/life/FamilyCoverage';
import LargeCarrierSelection from '../../components/insurance/life/LivingBenefits';
import ProductComparison from '../../components/insurance/life/ProductComparison';

export default function LifeInsurancePage() {
  const [activeTab, setActiveTab] = useState('individual');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="py-16 lg:py-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Life Insurance Solutions
            </h1>
            <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
              Comprehensive life insurance solutions designed to protect what matters most.
              Explore our range of products and find the perfect coverage for your clients.
            </p>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Life Insurance Navigation">
          <button
            onClick={() => setActiveTab('individual')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'individual' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Individual Plans
          </button>
          <button
            onClick={() => setActiveTab('family')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'family' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Family Coverage
          </button>
          <button
            onClick={() => setActiveTab('carriers')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'carriers' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Large Carrier Selection
          </button>
        </nav>

        {/* Content Sections */}
        <div className="mb-16">
          {activeTab === 'individual' && <IndividualPlans />}
          {activeTab === 'family' && <FamilyCoverage />}
          {activeTab === 'carriers' && <LargeCarrierSelection />}
        </div>

        {/* Product Comparison */}
        <ProductComparison />
      </div>
    </main>
  );
}