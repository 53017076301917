import { Shield, Award, TrendingUp } from 'lucide-react';
import PlanCard from '../shared/PlanCard';

const carrierSelection = [
  {
    name: 'Top-Rated Carriers',
    description: 'Partner with financially strong, industry-leading insurance providers',
    icon: Award,
    features: [
      'A- or better rated companies',
      'Financially stable partners',
      'Long-term industry presence',
      'Strong claims-paying ability'
    ],
    benefits: [
      'Client confidence',
      'Enhanced credibility',
      'Reliable product support',
      'Proven track record'
    ]
  },
  {
    name: 'Diverse Product Portfolio',
    description: 'Access a wide range of solutions to meet client needs',
    icon: Shield,
    features: [
      'Term life solutions',
      'Permanent life products',
      'Living benefit options',
      'Specialized insurance products'
    ],
    benefits: [
      'Meet any client need',
      'Competitive product offerings',
      'Flexibility for clients',
      'One-stop solution provider'
    ]
  },
  {
    name: 'Competitive Compensation',
    description: 'Industry-leading commission structures and bonus opportunities',
    icon: TrendingUp,
    features: [
      'Top-tier commission rates',
      'Performance bonuses',
      'Renewal commissions',
      'Special incentive programs'
    ],
    benefits: [
      'Maximize your earnings',
      'Rewarded for performance',
      'Long-term income stability',
      'Growth opportunities'
    ]
  }
];

export default function LargeCarrierSelection() {
  return (
    <div className="space-y-16">
      {/* Introduction */}
      <div className="rounded-2xl bg-gradient-to-br from-blue-50 to-white p-8">
        <h2 className="text-2xl font-bold text-gray-900">
          Expansive Carrier Network
        </h2>
        <p className="mt-2 text-gray-600">
          Gain access to our extensive portfolio of top-rated insurance carriers.
          Our large carrier selection empowers you to offer the perfect solution for
          every client situation with competitive products and compensation.
        </p>
      </div>

      {/* Benefits Grid */}
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {carrierSelection.map((benefit) => (
          <PlanCard key={benefit.name} plan={benefit} />
        ))}
      </div>

      {/* Additional Information */}
      <div className="rounded-2xl bg-gray-50 p-8">
        <h3 className="text-lg font-semibold text-gray-900">
          Carrier Partnerships
        </h3>
        <ul className="mt-4 space-y-3">
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
            Direct contracting with major carriers available through our platform
          </li>
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
            Dedicated carrier support representatives for each product line
          </li>
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
            Simplified contracting and onboarding process with all carriers
          </li>
        </ul>
      </div>
    </div>
  );
}