import { PiggyBank, TrendingUp, Lock } from 'lucide-react';
import PlanCard from '../../components/insurance/shared/PlanCard';
import { ANNUITY_SOLUTIONS } from '../../constants/content/insurance/annuity';

const plans = [
  {
    name: 'Fixed Annuities',
    description: 'Guaranteed interest rates and principal protection',
    icon: Lock,
    features: [
      'Guaranteed interest rates',
      'Principal protection',
      'Tax-deferred growth',
      'Predictable income options'
    ],
    benefits: [
      'Stable returns',
      'No market risk',
      'Guaranteed income',
      'Estate planning tool'
    ]
  },
  {
    name: 'Fixed Indexed Annuities',
    description: 'Market-linked potential with downside protection',
    icon: TrendingUp,
    features: [
      'Index-linked returns',
      'Principal protection',
      'Multiple index options',
      'Flexible crediting methods'
    ],
    benefits: [
      'Growth potential',
      'Downside protection',
      'Tax-deferred growth',
      'Optional income riders'
    ]
  },
  {
    name: 'Multi-Year Guarantee Annuities',
    description: 'Locked-in rates for extended periods',
    icon: PiggyBank,
    features: [
      'Guaranteed rate period',
      'Multiple term options',
      'Competitive rates',
      'Systematic growth'
    ],
    benefits: [
      'Rate certainty',
      'Predictable growth',
      'Tax advantages',
      'Legacy planning'
    ]
  }
];

export default function AnnuityPage() {
  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="py-16 lg:py-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {ANNUITY_SOLUTIONS.pageTitle}
            </h1>
            <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
              {ANNUITY_SOLUTIONS.heroSection.subheadline}
            </p>
          </div>
        </div>

        {/* Plans Grid */}
        <div className="mb-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {plans.map((plan) => (
            <PlanCard key={plan.name} plan={plan} />
          ))}
        </div>

        {/* Comparison Table */}
        <div className="mb-16 overflow-hidden rounded-xl bg-white shadow ring-1 ring-gray-200">
          <div className="p-6 sm:p-8">
            <h2 className="text-xl font-semibold text-gray-900">
              {ANNUITY_SOLUTIONS.comparisonTable.title}
            </h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {ANNUITY_SOLUTIONS.comparisonTable.headers.map((header, idx) => (
                    <th 
                      key={idx}
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {ANNUITY_SOLUTIONS.comparisonTable.rows.map((row, idx) => (
                  <tr key={idx} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                      {row.feature}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {row.fixed}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {row.indexed}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {row.myga}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Important Information */}
        <div className="mb-16 rounded-2xl bg-blue-50 p-8">
          <h2 className="text-lg font-semibold text-gray-900">
            {ANNUITY_SOLUTIONS.importantInformation.title}
          </h2>
          <ul className="mt-4 space-y-3">
            {ANNUITY_SOLUTIONS.importantInformation.points.map((point, idx) => (
              <li key={idx} className="flex items-center text-sm text-gray-600">
                <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                <strong>{point.title}</strong>: {point.description}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
}